'use client'

import { Button } from '@mui/material'

// Types
import type { TextButtonProps } from '@/types/components/atoms/buttons/text'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const PrimaryButtonAtom = (props: TextButtonProps) => {
	// Props
	const { title, startIcon, endIcon, onClick } = props

	// Varaibles
	const { t } = useTranslation()

	return (
		<Button
			variant="contained"
			disableElevation
			startIcon={startIcon}
			endIcon={endIcon}
			onClick={onClick}
			sx={{
				py: 1.5,
				px: 3,
				fontWeight: 700,
				letterSpacing: -0.3,
				textTransform: 'inherit',
				color: 'primary.contrastText',
				bgcolor: 'primary.dark',
				'&:hover': {
					color: 'primary.contrastText',
					bgcolor: 'primary.dark'
				}
			}}
		>
			{t(title)}
		</Button>
	)
}

export default PrimaryButtonAtom
