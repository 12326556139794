'use client'

import { Typography } from '@mui/material'

// Types
import type { TypographiesProps } from '@/types/components/atoms/typographies'

// Helpers
import { useTranslation } from '@/helpers/i18n/client'

const ParagraphTypographyAtom = (props: TypographiesProps) => {
	// Props
	const { text } = props

	// Varaibles
	const { t } = useTranslation()

	return <Typography variant="body2">{t(text)}</Typography>
}

export default ParagraphTypographyAtom
